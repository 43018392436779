const routes = [
//     {
//     name: 'test',
//     path: 'test',
//     component: () =>
//         import ('@/qixianggongyingpingtai/views/test/index.vue'),
//     meta: {
//         title: '发票开票',
//         breadcrumb: [{
//             "path": "",
//             "title": "发票"
//         }, {
//             "path": "/home/invoceManagerList",
//             "title": "发票开票"
//         }],
//     }
// }
{
    name: 'advertisingManagement',
    path: 'advertisingManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/advertisingManagement/index.vue'),
    meta: {
        title: '广告管理',
        breadcrumb: [
            {
            "path": "",
            "title": "广告管理"
            }
        ],
    }
},
{
    name: 'typeManagement',
    path: 'typeManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/index.vue'),
    meta: {
        title: '类别管理',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
            },
            {
            "path": "typeManagement",
            "title": "类别设置"
            },
        ],
    }
},
{
    name: 'typeAddOrEdit',
    path: 'typeAddOrEdit',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/addoredit.vue'),
    meta: {
        title: '类别管理',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
            },
            {
            "path": "/home/typeManagement",
            "title": "类别设置"
            },
            {
                "path": "",
                "title": "编辑"
                },
        ],
    }
},
{
    name: 'typeAdd',
    path: 'typeAdd',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/addoredit.vue'),
    meta: {
        title: '类别管理',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
            },
            {
                "path": "/home/typeManagement",
                "title": "类别设置"
                },
            {
            "path": "typeAdd",
            "title": "新增"
            },
        ],
    }
},
{
    name: 'productManagement',
    path: 'productManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/productList.vue'),
    meta: {
        title: '产品管理',
        breadcrumb: [
            {
            "path": "",
            "title": "产品管理"
            },
            {
                "path": "productManagement",
                "title": "产品管理"
            },
        ],
    }
},
{
    name: 'productAdd',
    path: 'productAdd',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/productAdd.vue'),
    meta: {
        title: '新增产品',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
                },
            {
            "path": "/home/productManagement",
            "title": "产品管理"
            },
            {
                "path": "",
                "title": "新增产品"
            },
        ],
    }
},
{
    name: 'productEdit',
    path: 'productEdit',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/productAdd.vue'),
    meta: {
        title: '编辑产品',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
                },
            {
                "path": "/home/productManagement",
                "title": "产品管理"
                },
            {
            "path": "",
            "title": "编辑产品"
        },
    ],
    }
},
{
    name: 'productView',
    path: 'productView',
    component: () =>
        import ('@/qixianggongyingpingtai/views/productManagement/productAdd.vue'),
    meta: {
        title: '查看产品',
        breadcrumb: [
            {
                "path": "",
                "title": "产品管理"
                },
            {
                "path": "/home/productManagement",
                "title": "产品管理"
                },
            {
            "path": "",
            "title": "查看产品"
        }],
    }
},
{
    name: 'packegeProduct',
    path: 'packegeProduct',
    component: () =>
        import ('@/qixianggongyingpingtai/views/packageManagement/packegeProduct.vue'),
    meta: {
        title: '新增套餐',
        breadcrumb: [{
            "path": "",
            "title": "新增套餐"
        }],
    }
},
{
    name: 'packegeProductEdit',
    path: 'packegeProductEdit',
    component: () =>
        import ('@/qixianggongyingpingtai/views/packageManagement/packegeProduct.vue'),
    meta: {
        title: '编辑套餐',
        breadcrumb: [{
            "path": "",
            "title": "编辑套餐"
        }],
    }
},
{
    name: 'packegeProductView',
    path: 'packegeProductView',
    component: () =>
        import ('@/qixianggongyingpingtai/views/packageManagement/packegeProduct.vue'),
    meta: {
        title: '查看套餐',
        breadcrumb: [{
            "path": "",
            "title": "查看套餐"
        }],
    }
},
{
    name: 'packageManagement',
    path: 'packageManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/packageManagement/packageList.vue'),
    meta: {
        title: '套餐管理',
        breadcrumb: [
            {
            "path": "",
            "title": "套餐管理"
            },
            {
                "path": "packageManagement",
                "title": "套餐管理"
            },
        ],
    }
},
{
    name: 'classifyManagement',
    path: 'classifyManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/packageManagement/index.vue'),
    meta: {
        title: '类别设置',
        breadcrumb: [
            {
                "path": "",
                "title": "套餐管理"
            },
            {
                "path": "classifyManagement",
                "title": "类别设置"
            },
    ],
    }
},
{
    name: 'lightingSchemeManagment',
    path: 'lightingSchemeManagment',
    component: () =>
        import ('@/qixianggongyingpingtai/views/lightingSchemeManagment/index.vue'),
    meta: {
        title: '照明方案管理',
        breadcrumb: [{
            "path": "",
            "title": "照明方案管理"
        }],
    }
},
{
    name: 'lightlingAdd',
    path: 'lightlingAdd',
    component: () =>
        import ('@/qixianggongyingpingtai/views/lightingSchemeManagment/lightlingAdd.vue'),
    meta: {
        title: '新增照明方案',
        breadcrumb: [{
            "path": "",
            "title": "新增照明方案"
        }],
    }
},
{
    name: 'lightlingEdit',
    path: 'lightlingEdit',
    component: () =>
        import ('@/qixianggongyingpingtai/views/lightingSchemeManagment/lightlingAdd.vue'),
    meta: {
        title: '编辑照明方案',
        breadcrumb: [{
            "path": "",
            "title": "编辑照明方案"
        }],
    }
},
{
    name: 'lightlingView',
    path: 'lightlingView',
    component: () =>
        import ('@/qixianggongyingpingtai/views/lightingSchemeManagment/lightlingAdd.vue'),
    meta: {
        title: '查看照明方案',
        breadcrumb: [{
            "path": "",
            "title": "查看照明方案"
        }],
    }
},
{
    name: 'userManagement',
    path: 'userManagement',
    component: () =>
        import ('@/qixianggongyingpingtai/views/userManagement/index.vue'),
    meta: {
        title: '客户管理',
        breadcrumb: [{
            "path": "",
            "title": "客户管理"
        }],
    }
},
{
    name: 'orderHistory',
    path: 'orderHistory',
    component: () =>
        import ('@/qixianggongyingpingtai/views/order/index.vue'),
    meta: {
        title: '订单记录',
        breadcrumb: [ {
            "path": "",
            "title": "订单管理"
        },
        {
            "path": "orderHistory",
            "title": "订单记录"
        },],
    }
},
{
    name: 'orderHistoryDetail',
    path: 'orderHistoryDetail',
    component: () =>
        import ('@/qixianggongyingpingtai/views/order/detail.vue'),
    meta: {
        title: '订单详情',
        breadcrumb: [ {
            "path": "",
            "title": "订单管理"
        },
        {
            "path": "orderHistoryDetail",
            "title": "订单详情"
        },],
    }
},
]



export default routes