//导入枪支模块路由
import gisRangeManageRouters from '@/gisRangeManage/router'
import shoppingMallRoutes from "@/shoppingMall/route"
import epidemicControlRoutes from "@/epidemicControl/route"
import checkDuplicatesRoutes from "@/checkDuplicates/route"
import informationManageRoutes from "@/informationManage/route"
import concreteRoutes from "@/concrete/route"

import qixianggongyingpingtaiRoutes from "@/qixianggongyingpingtai/route"

//环境安全情况
import huanjinganquanjiances from "@/huanjinganquanjiance/route"
const routes = [{
        name: 'loginConcrete',
        path: '/loginConcrete',
        component: () =>
            import ('@/concrete/views/login/index.vue'), //混泥土登录
        meta: {
            isNotrequireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        }
    }, {
        name: 'login_v1',
        path: '/login_v1',
        component: () =>
            import ('@/managerUser/views/login/login_v1.vue'),
        meta: {
            isNotrequireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        }
    }, {
        name: 'login_v2',
        path: '/login_v2',
        component: () =>
            import ('@/managerUser/views/login/login_v2.vue'),
        meta: {
            isNotrequireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        }
    },
    {
        name: 'Home',
        path: '/home',
        component: () =>
            import ('@/managerUser/views/home'),
        redirect: {
            name: 'welcome'
        },
        children: [{
                name: 'welcome',
                path: 'welcome',
                component: () =>
                    import ('@/managerUser/views/home/welcome.vue'),
                meta: {
                    title: '首页',
                    breadcrumb: [{
                        "path": "",
                        "title": "首页"
                    }],
                    // isNotrequireAuth: true
                }
            }, {
                name: 'organizationManagerList',
                path: 'organizationManagerList',
                component: () =>
                    import ('@/managerUser/views/home/organizationManager/organizationManagerList.vue'),
                meta: {
                    title: '角色管理',
                    breadcrumb: [{
                        "path": "",
                        "title": "权限管理"
                    }, {
                        "path": "/home/organizationManagerList",
                        "title": "组织管理"
                    }],
                    // isNotrequireAuth: true
                }
            }, {
                name: 'roleManagerList',
                path: 'roleManagerList',
                component: () =>
                    import ('@/managerUser/views/home/roleManager/roleManagerList.vue'),
                meta: {
                    title: '角色管理',
                    breadcrumb: [{
                        "path": "",
                        "title": "权限管理"
                    }, {
                        "path": "/home/roleManagerList",
                        "title": "角色管理"
                    }],
                    // isNotrequireAuth: true
                }
            }, {
                name: 'userManagerList',
                path: 'userManagerList',
                component: () =>
                    import ('@/managerUser/views/home/userManager/userManagerList.vue'),
                meta: {
                    title: '用户管理',
                    breadcrumb: [{
                        "path": "",
                        "title": "权限管理"
                    }, {
                        "path": "/home/userManagerList",
                        "title": "用户管理"
                    }],
                    // isNotrequireAuth: true
                }
            },
            ...gisRangeManageRouters,
            ...shoppingMallRoutes,
            ...epidemicControlRoutes,
            ...checkDuplicatesRoutes,
            ...informationManageRoutes,
            ...huanjinganquanjiances,
            ...concreteRoutes,
            ...qixianggongyingpingtaiRoutes
        ]
    }
]

export default routes