const routes = [{
        name: 'hesuanInfoList',
        path: 'epidemicControl/hesuanInfo/hesuanInfoList',
        component: () =>
            import ('@/epidemicControl/views/home/hesuanInfo/hesuanInfoList.vue'),
        meta: {
            title: '核酸信息',
            breadcrumb: [{ "path": "", "title": "疫情防控" }, { "title": "核酸信息" }]
        }
    },
    {
        name: 'vaccineInoculationList',
        path: 'epidemicControl/vaccineInoculationInfo/vaccineInoculationList',
        component: () =>
            import ('@/epidemicControl/views/home/vaccineInoculationInfo/vaccineInoculationList.vue'),
        meta: {
            title: '疫苗信息',
            breadcrumb: [{ "path": "", "title": "疫情防控" }, { "title": "疫苗信息" }]
        }
    },
    {
        name: 'epidemiccontrolResidentpersonInfoList',
        path: 'epidemicControl/epidemiccontrolResidentpersonInfo/epidemiccontrolResidentpersonInfoList',
        component: () =>
            import ('@/epidemicControl/views/home/epidemiccontrolResidentpersonInfo/epidemiccontrolResidentpersonInfoList.vue'),
        meta: {
            title: '疫苗信息',
            breadcrumb: [{ "path": "", "title": "疫情防控" }, { "title": "居住人口信息" }]
        }
    },
    {
        name: 'epidemiccontrolPlantoreturnInfoList',
        path: 'epidemicControl/epidemiccontrolPlantoreturnInfo/epidemiccontrolPlantoreturnInfoList',
        component: () =>
            import ('@/epidemicControl/views/home/epidemiccontrolPlantoreturnInfo/epidemiccontrolPlantoreturnInfoList.vue'),
        meta: {
            title: '疫苗信息',
            breadcrumb: [{ "path": "", "title": "疫情防控" }, { "title": "拟返乡人员" }]
        }
    },
    {
        name: 'epidemiccontrolDataSearchIndex',
        path: 'epidemicControl/dataSearch/index',
        component: () =>
            import ('@/epidemicControl/views/home/dataSearch/index.vue'),
        meta: {
            title: '疫苗信息',
            breadcrumb: [{ "path": "", "title": "疫情防控" }, { "title": "数据检索" }]
        }
    },


]

export default routes;