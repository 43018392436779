const routes = [
    {
        name: 'invoceManagerList',
        path: 'invoceManagerList',
        component: () =>
            import ('@/concrete/views/invoice/index.vue'),
        meta: {
            title: '发票开票',
            breadcrumb: [{
                "path": "",
                "title": "发票"
            }, {
                "path": "/home/invoceManagerList",
                "title": "发票开票"
            }],
        }
    },
    {
        name: 'invoceManagerDetail',
        path: 'invoceManagerDetail',
        component: () =>
            import ('@/concrete/views/invoice/detail.vue'),
        meta: {
            title: '发票开票',
            breadcrumb: [{
                "path": "",
                "title": "发票"
            }, {
                "path": "/home/invoceManagerDetail",
                "title": "发票详情"
            }],
        }
    },
    {
        name: 'concreteUserMannagement',
        path: 'concreteUserMannagement',
        component: () =>
            import ('@/concrete/views/userMannagement/user/index.vue'),
        meta: {
            title: '用户管理',
            breadcrumb: [{
                "path": "",
                "title": "用户管理"
            }, {
                "path": "/home/concreteUserMannagement",
                "title": "用户管理"
            }],
        }
    },
    {
        name: 'concreteRoleMannagement',
        path: 'concreteRoleMannagement',
        component: () =>
            import ('@/concrete/views/userMannagement/role/index.vue'),
        meta: {
            title: '角色管理',
            breadcrumb: [{
                "path": "",
                "title": "用户管理"
            }, {
                "path": "/home/concreteRoleMannagement",
                "title": "角色管理"
            }],
        }
    },
    {
        name: 'concreteOrderMannagement',
        path: 'concreteOrderMannagement',
        component: () =>
            import ('@/concrete/views/order/index.vue'),
        meta: {
            title: '订单管理',
            breadcrumb: [{
                "path": "",
                "title": "订单"
            }, {
                "path": "/home/concreteOrderMannagement",
                "title": "订单管理"
            }],
        }
    },
    {
        name: 'concreteIntelligentDesignMixRatioMannagement',
        path: 'concreteIntelligentDesignMixRatioMannagement',
        component: () =>
            import ('@/concrete/views/taskRecord/IntelligentDesignMixRatio/index.vue'),
        meta: {
            title: '配合比智能设计',
            breadcrumb: [{
                "path": "",
                "title": "任务记录"
            }, {
                "path": "/home/concreteIntelligentDesignMixRatioMannagement",
                "title": "配合比智能设计"
            }],
        }
    },
    {
        name: 'concretePredictionMixIntensityMannagement',
        path: 'concretePredictionMixIntensityMannagement',
        component: () =>
            import ('@/concrete/views/taskRecord/PredictionMixIntensity/index.vue'),
        meta: {
            title: '配合比强度预测',
            breadcrumb: [{
                "path": "",
                "title": "任务记录"
            }, {
                "path": "/home/concretePredictionMixIntensityMannagement",
                "title": "配合比强度预测"
            }],
        }
    },
    {
        name: 'concreteSetProductPriceMannagement',
        path: 'concreteSetProductPriceMannagement',
        component: () =>
            import ('@/concrete/views/set/setProductPrice/index.vue'),
        meta: {
            title: '产品价格设置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concreteSetProductPriceMannagement",
                "title": "产品价格设置"
            }],
        }
    },
    {
        name: 'concretePreferentialConfigurationMannagement',
        path: 'concretePreferentialConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/preferentialConfiguration/index.vue'),
        meta: {
            title: '优惠配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretePreferentialConfigurationMannagement",
                "title": "优惠配置"
            }],
        }
    },
    {
        name: 'concreteuserInitialGoldConfigurationMannagement',
        path: 'concreteuserInitialGoldConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/userInitialGoldConfiguration/index.vue'),
        meta: {
            title: '用户初始金币配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concreteuserInitialGoldConfigurationMannagement",
                "title": "用户初始金币配置"
            }],
        }
    },
    {
        name: 'concreteconcreteCharacteristicConfigurationMannagement',
        path: 'concreteconcreteCharacteristicConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/concreteCharacteristicConfiguration/index.vue'),
        meta: {
            title: '混凝土特性配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concreteconcreteCharacteristicConfigurationMannagement",
                "title": "混凝土特性配置"
            }],
        }
    },
    {
        name: 'concreteadmixtureConfigurationMannagement',
        path: 'concreteadmixtureConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/admixtureConfiguration/index.vue'),
        meta: {
            title: '掺合料配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concreteadmixtureConfigurationMannagement",
                "title": "掺合料配置"
            }],
        }
    },
    {
        name: 'concretespecialMaterialConfigurationMannagement',
        path: 'concretespecialMaterialConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/specialMaterialConfiguration/index.vue'),
        meta: {
            title: '特殊材料配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretespecialMaterialConfigurationMannagement",
                "title": "特殊材料配置"
            }],
        }
    },
    {
        name: 'concretespecialDesignConfigurationMannagement',
        path: 'concretespecialDesignConfigurationMannagement',
        component: () =>
            import ('@/concrete/views/set/specialDesignConfiguration/index.vue'),
        meta: {
            title: '特殊设计配置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretespecialDesignConfigurationMannagement",
                "title": "特殊设计配置"
            }],
        }
    },
    {
        name: 'concretemixRatioDesignScoreSettingMannagement',
        path: 'concretemixRatioDesignScoreSettingMannagement',
        component: () =>
            import ('@/concrete/views/set/mixRatioDesignScoreSetting/index.vue'),
        meta: {
            title: '配合比设计评分设置',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretemixRatioDesignScoreSettingMannagement",
                "title": "配合比设计评分设置"
            }],
        }
    },
    {
        name: 'concretecommonProblemDetailMannagement',
        path: 'concretecommonProblemDetailMannagement',
        component: () =>
            import ('@/concrete/views/set/commonProblem/detail.vue'),
        meta: {
            title: '常见问题',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretecommonProblemDetailMannagement",
                "title": "常见问题"
            }],
        }
    },
    {
        name: 'concretecommonProblemMannagement',
        path: 'concretecommonProblemMannagement',
        component: () =>
            import ('@/concrete/views/set/commonProblem/index.vue'),
        meta: {
            title: '问题编辑',
            breadcrumb: [{
                "path": "",
                "title": "设置"
            }, {
                "path": "/home/concretecommonProblemMannagement",
                "title": "问题编辑"
            }],
        }
    },
    {
        name: 'concretecompanyManagement',
        path: 'concretecompanyManagement',
        component: () =>
            import ('@/concrete/views/companyManagement/index.vue'),
        meta: {
            title: '管理员管理',
            breadcrumb: [{
                "path": "",
                "title": "公司管理"
            }, {
                "path": "/home/concretecompanyManagement",
                "title": "管理员管理"
            }],
        }
    },
]



export default routes