const routes = [{
        name: 'goosTypeTree',
        path: 'shoppingMall/goodsType/goodsTypeTree',
        component: () =>
            import ('@/shoppingMall/views/home/goodsType/goodsTypeTree.vue'),
        meta: {
            title: '商品类型',
            breadcrumb: [{ "path": "", "title": "商品类型" }, { "title": "商品类型管理" }]
        }
    }, {
        name: 'orderListAll',
        path: 'shoppingMall/order/orderListAll',
        component: () =>
            import ('@/shoppingMall/views/home/order/orderListAll.vue'),
        meta: {
            title: '全部订单',
            breadcrumb: [{ "path": "", "title": "订单管理" }, { "title": "全部订单" }]
        }
    },
    {
        name: 'needDeliverOrderList',
        path: 'shoppingMall/order/needDeliverOrderList',
        component: () =>
            import ('@/shoppingMall/views/home/order/needDeliverOrderList.vue'),
        meta: {
            title: '待发货',
            breadcrumb: [{ "path": "", "title": "订单管理" }, { "title": "待发货" }]
        }
    },




]

export default routes;