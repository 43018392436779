const routes = [{
        path: "*",
        redirect: {
            // name: "loginConcrete"
            name: "login_v1"
            // name: "login_v2"
        }
    },
    {
        path: "/",
        redirect: {
            // name: "loginConcrete"
            name: "login_v1"
            // name: "login_v2"
        }
    }
]



export default routes