//所有其他项目mixin 请到这里添加
import lpyMixin from '@/base/mixin/lpyMixin'
import firearmsMangerMixin from '@/gisRangeManage/mixin/firearmsMangerMixin'
//商城相关
// import shoppingMallMixin from '@/shoppingMall/mixin/shoppingMallMixin'

// 查重相关
import checkDuplicatesMixin from '@/checkDuplicates/mixin/checkDuplicatesMixin'

/**
 * 
 * 
 * 混入中严谨加入create方法调用
 * 混入中严谨加入create方法调用
 * 混入中严谨加入create方法调用
 * 混入中严谨加入create方法调用
 * 混入中严谨加入create方法调用
 * 
 * 
 */
//import other from '@/projectname/mixin/otherMixin'
export default {
    install(Vue) {
        Vue.mixin(lpyMixin)
        Vue.mixin(firearmsMangerMixin)
        Vue.mixin(checkDuplicatesMixin)
    }
}