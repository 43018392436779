const routes = [
    // {
    //     name: 'goosTypeTree',
    //     path: 'shoppingMall/goodsType/goodsTypeTree',
    //     component: () =>
    //         import ('@/shoppingMall/views/home/goodsType/goodsTypeTree.vue'),
    //     meta: {
    //         title: '商品类型',
    //         breadcrumb: [{ "path": "", "title": "商品类型" }, { "title": "商品类型管理" }]
    //     }
    // }, 
    {
        name: 'orderListAll',
        path: 'checkDuplicates/order/orderListAll',
        component: () =>
            import('@/checkDuplicates/views/home/order/orderListAll.vue'),
        meta: {
            title: '订单管理',
            breadcrumb: [{
                path: '',
                "title": "查重管理"
            }, {
                "title": "订单管理"
            }]
        }
    },
    {
        name: 'checkManagement',
        path: 'checkDuplicates/checkManagement',
        component: () =>
            import('@/checkDuplicates/views/home/checkManagement/index.vue'),
        meta: {
            title: '查重进度',
            breadcrumb: [{
                path: '',
                "title": "查重管理"
            }, {
                "title": "查重进度"
            }]
        }
    },




]

export default routes;