const routes = [


    {
        name: 'deviceManager',
        path: 'huanjinganquanjiance/fsuManage/fsuList',
        component: () =>
            import ('@/huanjinganquanjiance/views/fsuManage/fsuList'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "资讯管理",
                path: '/home/informationManage/contentManagement'
            }, {
                "title": "编辑"
            }]
        }
    },



]

export default routes;