//解决页面布局 table高度适配问题，不是每个页面都实用，如果需要使用这个插件
//就需要在需要根据高度改变的table或者容器  利用 全局变量 tableHeight 设定对应容器高度
//例如：<el-table :height="tableHeight">
var lpyMixin = {
    data: function() {
        return {
            //处理默认图片onerror
            imgError: "this.src='/favicon.ico'",
            tableHeight: 0,
            imageRootPath: "/qixianggongyingpingtai_api/common/backstage/file/fileDownLoad?filePath="
        }
    },
    created() {
        this.resetSize();
    },
    beforeMount() {
        window.addEventListener("resize", this.resetSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resetSize);
    },
    methods: {
        resetSize() {
            let h = document.documentElement.clientHeight || document.body.clientHeight;

            this.tableHeight = h - 300;
        },
        resetSearchCondtion(searchCondition) {
            for (let key in searchCondition) {
                if (key == "pageSize") {
                    continue;
                } else if (key == "pageNum") {
                    this.searchCondition[key] = 1;
                } else {
                    this.searchCondition[key] = "";
                }
            }
        }
    },
    filters: {

    }
}
export default lpyMixin;