var checkDuplicatesMixin = {
    filters: {
        /**
         * 订单渠道
         * @param {}} val 
         * @returns 
         */
        fromChannelFilters(val) {
            //订单渠道 1-APP 2-小程序 3-后台
            if (val == "1") {
                return "App";
            } else if (val == "2") {
                return "小程序";
            } else if (val == "3") {
                return "后台";
            } else {
                return "未知";
            }
        },
        /**
         * 订单状态过滤器
         * @param {*} val 
         * @returns 
         */
        orderStateFilters(val) {
            //订单状态1-待查重 2-已完成
            if (val == "1") {
                return "待查重";
            } else if (val == "2") {
                return "已完成";
            } else {
                return "未知";
            }
        },

    }
}
export default checkDuplicatesMixin