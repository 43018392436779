import Vue from 'vue'
import App from './App.vue'
import router from './globalRouter'
import store from './base/store'
import ElementUI from 'element-ui';
import './theme/theme_blue/index.css'
import globalMixin from './globalMixin'
import { v4 as uuidv4 } from "uuid";
Vue.prototype.$uuidv4 = uuidv4
Vue.use(ElementUI);
Vue.use(globalMixin)
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')