import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        access_token: sessionStorage.getItem('access_token') || "",
        //access_token: localStorage.getItem('access_token') || "",
    },
    mutations: {
        access_token(state, data) {
            state.access_token = data
            sessionStorage.setItem('access_token', data)
                //localStorage.setItem('access_token', data)
        }
    },
    actions: {},
    modules: {}
})