const routes = [
    // {
    //     name: 'orderListAll',
    //     path: 'checkDuplicates/order/orderListAll',
    //     component: () =>
    //         import('@/checkDuplicates/views/home/order/orderListAll.vue'),
    //     meta: {
    //         title: '订单管理',
    //         breadcrumb: [{
    //             path: '',
    //             "title": "查重管理"
    //         }, {
    //             "title": "订单管理"
    //         }]
    //     }
    // },


    {
        name: 'advertiseManage',
        path: 'informationManage/advertiseManage',
        component: () =>
            import('@/informationManage/views/advertiseManage'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "广告管理"
            }]
        }
    },
    {
        name: 'addAdvertise',
        path: 'informationManage/advertiseManage/addAdvertise',
        component: () =>
            import('@/informationManage/views/advertiseManage/addAdvertise'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "广告管理",
                path: '/home/informationManage/advertiseManage'
            }, {
                "title": "新增"
            }]
        }
    },
    {
        name: 'editAdvertise',
        path: 'informationManage/advertiseManage/editAdvertise',
        component: () =>
            import('@/informationManage/views/advertiseManage/addAdvertise'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "广告管理",
                path: '/home/informationManage/advertiseManage'
            }, {
                "title": "编辑"
            }]
        }
    },
    {
        name: 'contentManagement',
        path: 'informationManage/contentManagement',
        component: () =>
            import('@/informationManage/views/contentManagement'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "资讯管理"
            }]
        }
    },
    {
        name: 'addContent',
        path: 'informationManage/contentManagement/addContent',
        component: () =>
            import('@/informationManage/views/contentManagement/addContent'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "资讯管理",
                path: '/home/informationManage/contentManagement'
            }, {
                "title": "新增"
            }]
        }
    },
    {
        name: 'editContent',
        path: 'informationManage/contentManagement/editContent',
        component: () =>
            import('@/informationManage/views/contentManagement/addContent'),
        meta: {
            title: '内容管理',
            breadcrumb: [{
                path: '',
                "title": "内容管理"
            }, {
                "title": "资讯管理",
                path: '/home/informationManage/contentManagement'
            }, {
                "title": "编辑"
            }]
        }
    },



]

export default routes;