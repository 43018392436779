import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/base/store";
import baseRoutes from "@/base/router"
import managerUserRoutes from "@/managerUser/route"

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        ...baseRoutes,
        ...managerUserRoutes

    ]
})

router.beforeEach((to, from, next) => {
    /* 必须调用 `next` */
    //这里的意思是如果配置了路由 中meta.isNotrequireAuth  =true 标识不需要登陆，否则都需要登陆，
    //这样的目的是当前系统大多需要登陆拦截，所以这样简单一些
    if (to.meta.isNotrequireAuth) { // 判断该路由是否不需要登录权限
        next();
    } else {
        if (store.state.access_token) { // 通过vuex state获取当前的token是否存在
            next();
        } else { //if (1 == 1) { // 通过vuex state获取当前的token是否存在
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }

    }
})


export default router