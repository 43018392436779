const routes = [{
    name: 'electronicFenceArea',
    path: 'area/electronicFence',
    component: () =>
        import ('@/gisRangeManage/views/area/electronicFence.vue'),
    meta: {
        title: '电子围栏管理',
        breadcrumb: [{ "path": "", "title": "区域管理" }, { "title": "电子围栏管理" }]
    }
}, {
    name: 'security',
    path: 'area/security',
    component: () =>
        import ('@/gisRangeManage/views/area/security.vue'),
    meta: {
        title: '安全区域管理',
        breadcrumb: [{ "path": "", "title": "区域管理" }, { "title": "安全区域管理" }]
    }
}, {
    name: 'noEntry',
    path: 'area/noEntry',
    component: () =>
        import ('@/gisRangeManage/views/area/noEntry.vue'),
    meta: {
        title: '禁入区域管理',
        breadcrumb: [{ "path": "", "title": "区域管理" }, { "title": "禁入区域管理" }]
    }
}, {
    name: 'editGisArea',
    path: 'area/editGisArea',
    component: () =>
        import ('@/gisRangeManage/views/area/editGisArea.vue'),
    meta: {
        title: '编辑区域'
    }
}]

export default routes;