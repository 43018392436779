var firearmsMangerMixin = {
    filters: {
        taskStatusFilter: (value) => {
            if (value == 0) { //0 进行中
                return "进行中"
            } else if (value == 1) { //1 已经结束
                return "已经结束"
            } else {
                return "未知"
            }
        },

        //是否绑定定位器:1 已经绑定 0没有绑定
        isBandLocatorFilter: (value) => {
            if (value == 0) { //0 进行中
                return "未绑定"
            } else if (value == 1) { //1 已经结束
                return "已经绑定"
            } else {
                return "未知"
            }
        },


        //枪支状态,1-在位置 0-没在位置,
        gunStatusFilter: (value) => {
            if (value == 0) { //0 进行中
                return "没在位置"
            } else if (value == 1) { //1 已经结束
                return "在位置"
            } else {
                return "未知"
            }
        },


        //枪柜告警,17-未按时归还 31-枪支离位报警
        gunBoxAlarmFilter: (value) => {
            if (value == '17') { //17-未按时归还
                return "未按时归还"
            } else if (value == '31') { //31-枪支离位报警
                return "枪支离位报警"
            } else {
                return "未知"
            }
        },

        //定位器告警  31:分离告警 32：离线告警 33：电量低告警
        deviceAlarmTypeFilter: (value) => {
            if (value == '31') { //31-分离告警
                return "分离告警"
            } else if (value == '32') { //32-离线告警
                return "离线告警"
            } else if (value == '33') { //33-电量低告警
                return "电量低告警"
            } else {
                return "未知"
            }
        },

        //截取导入结果前6个字符
        spliteResultFilter: (value) => {
            if (value.length > 7) {
                return value.substring(0, 7) + "...";
            } else {
                return value;
            }

        },
        test: (value) => {
            if (value == '茶园派出所') {
                return "aa茶园派出所+xxxxx";
            } else {
                return "xxx测试xxxxx";
            }

        }

    }
}
export default firearmsMangerMixin